.sub-progressbar-btn {
  border-radius: 0%;
  background-color: #daecfd;
  padding: 5px;
  width: 100%;
  color: #0c90ef;
}

/* .sub-progressbar-btn.active::before {
  content: '>dkvhudvhu';
  position: absolute;
  border: 7px solid transparent;
  right: -100px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
} */

.sub-progressbar-btn.active {
  background-color: #2c6ff5; /* Change color when active */
  color: white;
}

.sub-progressbar-btn.done {
  background-color: #cddbf7; /* Change color when done */
  color: rgb(45, 141, 244);
}
