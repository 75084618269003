.login-page {
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #e0f7da, #ffe4c4);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  position: relative;
  overflow: hidden;
  opacity: 0.95;
}

/* Subtle Shape in Background 
   Removing Shapes in small screen as it is creating issue with captcha and the captcha 
   is getting disabled
*/
@media (min-width: 770px) {
  .login-page::before {
    content: '';
    position: absolute;
    top: -100px;
    right: -100px;
    width: 300px;
    height: 300px;
    background: rgba(255, 152, 0, 0.2);
    /* Light orange circle */
    border-radius: 50%;
    z-index: 0;
  }

  .login-page::after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: -100px;
    width: 400px;
    height: 400px;
    background: rgba(76, 175, 80, 0.15);
    /* Light green circle */
    border-radius: 50%;
    z-index: 0;
  }
}

/* Container Styling (Z-index ensures it's above background shapes) */
.container {
  background-color: white;
  padding: 40px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 400px;
  z-index: 1;
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.credentials:focus {
  border-color: #f39640 !important;
  border-radius: 2px;
}

.btn-login {
  background: #19dd70;
}

.btn-login:hover {
  background: #04b052dc;
}

.btn-login:active {
  background: #007d38f3 !important;
}

img {
  height: 55px;
}
