.btn{
    border-radius: 5px;
    padding: 6px 13px;
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
}


.edit-org{
    border: 1px solid #d2d2d2;
    padding: 5px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    background: #e3e3e3;
    color: #373B3E;
}

.icon{
    border: 1px solid #d2d2d2;
    padding: 5px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    background: #d1def9;
}

                                                                
.icon-lead-cards{
    border: 1px solid #d2d2d2;
    padding: 5px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    background: #55CE63;
}

.icon-lead-cards-calender{
    border: 1px solid #d2d2d2;
    color: #9368E9;
    padding: 5px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    background: #e0d8ef;
}

.client-activities:hover{
    color: rgb(242 11 111); 
}

.tab{
    color: black;
    text-decoration: none;
}

.tab.active{
    color: rgb(11, 103, 242);
    text-decoration: none;
}

.tab:hover{
    color: rgb(11, 103, 242);
    text-decoration: none;
}
.client-activities.active{
    border-bottom: 2px solid rgb(11, 103, 242); 
    color: rgb(11, 103, 242); 
}


.delete-btn{
    background: #6c757d;
    border: 1px solid #6c757d;
}

.delete-btn:hover{
    background: #6c757d;
    border: 1px solid #6c757d;
}

@media (max-width: 768px){
    .send-email{
        margin-top: 0.50rem;
    }
}

@media (max-width: 400px){
    .right-section{
        margin-top: 1rem;
    }
}

@media (max-width: 400px){
    #lead-cards{
        margin-top: 1rem;
    }
}
