body {
  background-color: #f3f6f9;
}

h1,
h2,
h3,
h4,
h5,
h6,
td,
th,
p,
.form-label,
.breadcrumb-item {
  font-family: Montserrat, sans-serif;
}

th {
  vertical-align: baseline;
}

.text-dark:hover {
  color: rgb(60, 66, 241) !important;
}

.add-btn {
  background: #13c56b;
  border: 1px solid #13c56b;
}

.add-btn:hover {
  background: #10a75b;
  border: 1px solid #10a75b;
}

.add-btn:active {
  background: #04934b !important;
  border: 1px solid #10a75b;
}

.filter-btn {
  background: #865ce2;
  border: 1px solid #865ce2;
}

.filter-btn:hover {
  background: #724ec0;
  border: 1px solid #724ec0;
}

.filter-btn:active {
  background: #724ec0 !important;
  border: 1px solid #724ec0;
}

/* Disable Bootstrap valid checkmark styling */
.was-validated .form-control:valid,
.was-validated .form-control.is-valid {
  background-image: none !important;
  box-shadow: none !important;
}

.was-validated .form-control:invalid,
.was-validated .form-control.is-valid {
  box-shadow: none !important;
  border: 1px solid #f51c04 !important;
}

.form-control {
  border: 1px solid #ced4da !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  box-shadow: none;
}

#error{
  border: 2px solid #f31313 !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  box-shadow: none;
}

.form-control:focus {
  border: 3px solid #4393ef !important;
  border-radius: 5px;
  box-shadow: none;
}

.form-select {
  border: 1px solid #ced4da !important;
  background: #f6f6f6 !important;
  border-radius: 5px;
  box-shadow: none;
}

.form-select:focus {
  border: 3px solid #4393ef !important;
  border-radius: 5px;
  box-shadow: none;
}


.form-check-input {
  background: #f6f6f6 !important;
}

.form-check-input:checked {
  border: 5px solid #0d6efd !important;
}

.download-btn {
  background-color: rgb(223, 242, 248);
  height: calc(1rem + 1.5em + 1px);
  width: calc(1rem + 1.5em + 2px);
  color: rgb(30, 193, 243) !important;
}

.download-btn:hover,
.download-btn:active {
  background-color: rgb(88, 209, 246);
  height: calc(1rem + 1.5em + 1px);
  width: calc(1rem + 1.5em + 2px);
  color: #fff !important;
}

.accordion-header{
  background-color: #FFF !important;
}

.row{
  padding-left: 0 !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b5b5; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media (max-width:400px){
  .cross-sell-form{
    margin-top: 0.50rem;
  }
}

ol {
  list-style-type: decimal; /* Ensure the numbers are shown */
}

/* Loader.css */
.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.card .card-body{
    overflow-wrap: break-word!important; /* For modern browsers */
    word-wrap: break-word;    /* For legacy support */
    word-break: break-word;   /* Optional, for further control */ 
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


