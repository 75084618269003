

.header-heading{
    font-weight: bold;
}

.breadcrumb-item{
    font-family:  sans-serif;
}

.small-logo{
    max-height: 1px;
}

.sidebar-items{
    color: rgb(67, 66, 66);
}

.sidebar-items:hover{
    color: #6691e7;
}

