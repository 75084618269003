.actions {
  position: absolute;
  right: 100%;
  /* Aligns the dropdown to the left of the three-dots */
  top: 0;
  /* Adjust this value as needed to align it vertically */
  z-index: 10;
  /* Ensure it appears above other content */
}

.d-flex {
  position: relative;
}


.custom-select option {
  background-color: #ffffff; 
  color: #333333; 
  padding: 10px;
}

.custom-select option:hover {
  background-color: #ff9800; 
  color: white;
}