.custom-switch-size .form-check-input {
    width: 40px;
    /* Adjust width */
    height: 20px;
    /* Adjust height */
}

.custom-switch-size .form-check-label {
    font-size: 1rem;
    /* Adjust font size */
    padding-left: 15px;
    /* Adjust spacing between switch and label */
}

.custom-switch-size .form-check-input:checked::before {
    width: 26px;
    height: 26px;
    transform: translateX(26px);
}


.custom-switch-size .form-check-input::before {
    width: 26px;
    height: 26px;
}

.fade-out {
    animation: fadeOut 6s ease-out forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

.bg-for-bluring{
  filter: blur(10px);
}
  

