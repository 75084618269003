

input[type=text]{
    background-color: #FFF;
    border: 1px solid #EAEAEA;
}

select[type=text]{
    background-color: #FFF;
    border: 1px solid #EAEAEA;
}

thead{
    background-color: #b5d1ec;
}

.breadcrumb-item+.breadcrumb-item::before{
    content: ">";
    margin: 0 5px;
}



