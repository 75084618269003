.bl-element{
    background-color: #fff;
    border: 1px solid black;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    padding: 8px;
    margin-bottom: 8px;
    text-align: center;
    width: max-content;
}


.left-col{
    border-right: 2px solid black;
}

