.csv-upload{
    border: 2px dashed rgb(189, 189, 189);
}

.upload-icon{
    color: rgb(112, 112, 112);
}

.csv-upload .dz-message h4{
    color: rgb(112, 112, 112);
}

.multi-select{
    max-height: 10px !important;
    padding: 0px;
}

.searchBox { 
	border: none;
	font-size: 10px;
	height: 24px;
    width: 100%;
  }

  